import classNames from "classnames"
import { createElement, ElementType, ReactNode } from "react"

import { Sprinkles, sprinkles } from "@/styles/sprinkles.css"

import * as styles from "./Text.css"

// We keep trim private because it is defined from baseline.
export type TextStyleProps = {
	baseline?: boolean
	align?: Sprinkles["textAlign"]
} & Omit<NonNullable<styles.TextVariants>, "trim"> &
	Omit<Sprinkles, "size" | "textAlign" | "color">
export const getTextStyle = ({
	baseline = false,
	size,
	color,
	weight,
	align,
	...sprinkleOptions
}: TextStyleProps) =>
	classNames(
		styles.text({
			size,
			color,
			weight,
			trim: baseline ? "trimmed" : "untrimmed",
		}),
		sprinkles({ textAlign: align, ...sprinkleOptions }),
	)

export type TextProps = TextStyleProps & {
	className?: string
	component?: ElementType
	children: ReactNode
}
export default function Text({
	baseline = true,
	size = "standard",
	color = "neutral",
	weight = "regular",
	display = "block",
	component = "span",
	className: classNameProp,
	children,
	...textStyleProps
}: TextProps) {
	let className = classNames(
		getTextStyle({
			...textStyleProps,
			baseline,
			size,
			color,
			weight,
			display,
		}),
		classNameProp,
	)
	return createElement(component, { className }, children)
}
