import classNames from "classnames"
import Link, { LinkProps } from "next/link"
import * as React from "react"

import * as styles from "./Button.css"
import { getTextStyle, TextStyleProps } from "./Text"

// If href is provided, this will be a <a> element, otherwise it will be a
// <button> element. href and onClick cannot be both provided.

type StyleProps = {
	size?: TextStyleProps["size"]
	icon?: React.ReactElement
	className?: string
} & styles.ButtonVariants
type HoverLinkProps = LinkProps & {
	onClick?: undefined
	type?: undefined
	children?: React.ReactNode
	download?: string
	useHTMLLink?: false
}
type HoverHTMLLinkProps = JSX.IntrinsicElements["a"] & {
	onClick?: undefined
	type?: undefined
	children?: React.ReactNode
	useHTMLLink: true
	download?: string
}
type HoverButtonProps = JSX.IntrinsicElements["button"] & {
	href?: undefined
	download?: undefined
	useHTMLLink?: false
}

export default function Button(
	props: (HoverButtonProps | HoverLinkProps | HoverHTMLLinkProps) & StyleProps,
): React.ReactElement {
	const {
		size = "standard",
		look = "contained",
		children,
		icon,
		useHTMLLink,
		className: classNameProp,
		...childProps
	} = props
	let className = classNames(styles.button({ look, size }), classNameProp)

	let content = (
		<>
			{icon == null
				? null
				: React.cloneElement(icon, {
						className: classNames(icon.props.className, styles.icon({ size })),
					})}
			<span className={getTextStyle({ baseline: true, size })}>{children}</span>
		</>
	)
	if (props.href == null) {
		return (
			<button
				{...(childProps as HoverButtonProps)}
				type={props.type == null ? "button" : props.type}
				className={className}
			>
				{content}
			</button>
		)
	}
	if (useHTMLLink === true) {
		return (
			<a
				{...(childProps as HoverLinkProps)}
				href={props.href}
				className={className}
			>
				{content}
			</a>
		)
	}
	return (
		<Link
			{...(childProps as HoverLinkProps)}
			href={props.href}
			className={className}
		>
			{content}
		</Link>
	)
}
